import React from 'react';
import { useIntl } from 'react-intl';
import Restricted from 'src/app/permissions/restricted';
import { MegaMenu } from './MegaMenu';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { MenuItem } from './MenuItem';

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuItem to="/dashboard" icon="/media/logos/logo-idefix.svg" logo />
      <div className="mx-5"></div>

      <Restricted to="ROLE_DASHBOARD">
        <MenuItem title={'Anasayfa'} to="/dashboard" />
      </Restricted>
      {/* <Restricted to="ROLE_USERS">
        <div className="position-relative d-lg-flex justify-content-center align-items-center">
          <div className="users-joyride">
            <MenuItem title="Kullanıcılar" to="/users" />
          </div>
        </div>
      </Restricted>
      <div className="position-relative d-lg-flex justify-content-center align-items-center">
        <div className="notes-joyride">
          <MenuItem title="Notlarım" to="/todos" />
        </div>
      </div>
      <Restricted to="ROLE_PERIODS">
        <div className="position-relative d-lg-flex justify-content-center align-items-center">
          <div className="periods-joyride">
            <MenuItem title="Dönemler" to="/periods" />
          </div>
        </div>
      </Restricted>
      <div className="position-relative d-lg-flex justify-content-center align-items-center">
        <div className="email-joyride">
          <MenuItem title="E-Posta" to="/email" />
        </div>
      </div>
      <Restricted to="ROLE_PRODUCTS">
        <div className="position-relative d-lg-flex justify-content-center align-items-center">
          <div className="product-joyride">
            <MenuItem title="Ürünler" to="/products" />
          </div>
          <span className="bullet bullet-dot bg-success mt-2 me-2 w-8px h-8px position-absolute translate-middle top-25 end-0 animation-blink"></span>
        </div>
      </Restricted> */}
      <Restricted to="ROLE_APPLICATIONS">
        <MenuInnerWithSub title="Öğrenci İşlemleri" to="/applications" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuInnerWithSub title="Kayıt İşlemleri" to="/applications/registration" hasArrow={true} menuPlacement="right-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
            <MenuItem to="/applications/registration" title="Başvurular" hasBullet={true} />
            <Restricted to="ROLE_APPLICATIONS_AGREEMENT">
              <MenuItem to="/applications/agreement" title="Sözleşmeler" hasBullet={true} />
            </Restricted>
          </MenuInnerWithSub>
          <Restricted to="ROLE_APPLICATIONS_EXAM">
            <MenuInnerWithSub title="Sınav İşlemleri" to="/applications/exam" hasArrow={true} menuPlacement="right-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
              <MenuItem to="/applications/exam/list" title="Başvurular" hasBullet={true} />
              <MenuItem to="/applications/exam/sessions" title="Oturumlar" hasBullet={true} />
            </MenuInnerWithSub>
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_SITE_OPERATIONS">
        <MenuInnerWithSub title="Site" to="/site-operations" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_SITE_OPERATIONS_ANNOUNCEMENTS">
            <MenuInnerWithSub
              title="Duyuru İşlemleri"
              to="/site-operations/announcement"
              hasArrow={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to="/site-operations/announcement/list" title="Duyurular" hasBullet={true} />
              <Restricted to="ROLE_SITE_OPERATIONS_ANNOUNCEMENTS_TYPE">
                <MenuItem to="/site-operations/announcement/types" title="Duyuru Tipleri" hasBullet={true} />
              </Restricted>
            </MenuInnerWithSub>
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_COMMUNICATION_OPERATIONS">
            <MenuInnerWithSub title="İletişim İşlemleri" to="/site-operations/contact" hasArrow={true} menuPlacement="right-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
              <Restricted to="ROLE_SITE_OPERATIONS_COMMUNICATION_OPERATIONS_SETTINGS">
                <MenuItem to="/site-operations/contact/info" title="İletişim Ayarları" hasBullet={true} />
              </Restricted>
              <Restricted to="ROLE_SITE_OPERATIONS_COMMUNICATION_OPERATIONS_CONTACT_REQUESTS">
                <MenuItem to="/site-operations/contact/list" title="İletişim Talepleri" hasBullet={true} />
              </Restricted>
            </MenuInnerWithSub>
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_ABOUT">
            <MenuItem to="/site-operations/aboutus" title="Hakkımızda" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_FOUNDERS">
            <MenuItem to="/site-operations/founders" title="Kurucular" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_JOB_APPLICATIONS">
            <MenuItem to="/site-operations/job" title="İş Başvuruları" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_MENUS">
            <MenuItem to="/site-operations/menus" title="Menüler" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_PAGES">
            <MenuItem to="/site-operations/pages" title="Sayfalar" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_POPUP">
            <MenuItem to="/site-operations/popup" title="Pop-up" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_SEO_SETTINGS">
            <MenuItem to="/site-operations/seo" title="Seo Ayarları" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_SLIDE">
            <MenuItem to="/site-operations/slide" title="Slayt" />
          </Restricted>
          <Restricted to="ROLE_SITE_OPERATIONS_SUBSCRIBERS">
            <MenuItem to="/site-operations/subscribers" title="Aboneler" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_EMPLOYEES">
        <MenuInnerWithSub title="Personel" to="/employees" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_EMPLOYEES">
            <MenuItem to="/employees/lists" title="Personel Yönetimi" />
          </Restricted>
          <Restricted to="ROLE_MAJORS">
            <MenuItem to="/employees/majors" title="Branş Yönetimi" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_LESSONS">
        <MenuInnerWithSub title="Dersler" to="/lessons" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_LESSONS_MANAGEMENT">
            <MenuItem to="/lessons/management" title="Ders Yönetimi" />
          </Restricted>
          <Restricted to="ROLE_LESSONS_CLASSES">
            <MenuItem to="/lessons/classes" title="Sınıflar" />
          </Restricted>
          <Restricted to="ROLE_LESSONS_BRANCH">
            <MenuItem to="/lessons/branch" title="Şubeler" />
          </Restricted>
          <Restricted to="ROLE_LESSONS_TIMETABLE">
            <MenuItem to="/lessons/timetable" title="Ders Programı" />
          </Restricted>
          <Restricted to="ROLE_LESSONS_EXAMINATION">
            <MenuItem to="/lessons/examination" title="Yoklama" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_OPTICAL">
        <MenuInnerWithSub title="Sınavlar" to="/optical" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_OPTICAL_FORMS">
            <MenuItem to="/optical/form/list" title="Form Yönetimi" />
          </Restricted>
          <Restricted to="ROLE_OPTICAL_EXAMS">
            <MenuItem to="/optical/exams" title="Sınavlar" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_HOMEWORK_PROCEDURES">
        <MenuInnerWithSub title="Ödevler" to="/homework" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_HOMEWORKS">
            <MenuItem to="/homework/lists" title="Ödevler" />
          </Restricted>
          <Restricted to="ROLE_HOMEWORKS_EVALUATION">
            <MenuItem to="/homework/evaluation" title="Değerlendirme" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_FINANCE">
        <MenuInnerWithSub title="Finans" to="/finance" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_FINANCE_INCOME_EXPENSES">
            <MenuInnerWithSub title="Gelir/Gider İşlemleri" to="/finance/income" hasArrow={true} menuPlacement="right-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
              <MenuItem to="/finance/income/list" title="Gelir/Gider Bilgileri" hasBullet={true} />
              <Restricted to="ROLE_FINANCE_INCOME_EXPENSES_TYPE">
                <MenuItem to="/finance/income/types" title="Gelir/Gider Tipleri" hasBullet={true} />
              </Restricted>
            </MenuInnerWithSub>
          </Restricted>
          <Restricted to="ROLE_CURRENT_ACCOUNTS">
            <MenuItem to="/finance/current-accounts" title="Cari Hesap Yönetimi" />
          </Restricted>
          <Restricted to="ROLE_FINANCE_CASH">
            <MenuItem to="/finance/cashes" title="Kasalar" />
          </Restricted>
          <Restricted to="ROLE_FINANCE_BANK">
            <MenuItem to="/finance/banks" title="Bankalar" />
          </Restricted>
          <Restricted to="ROLE_BALANCE_TRANSFER">
            <MenuItem to="/finance/transfer" title="Virman İşlemleri" />
          </Restricted>
          <Restricted to="ROLE_FINANCE_PAYMENT">
            <MenuItem to="/finance/operations/lists" title="Ödemeler" />
          </Restricted>
          <Restricted to="ROLE_FINANCE_PAYMENT_GIB">
            <MenuItem to="/finance/operations/e-arsiv" title="G.İ.B e-arşiv" />
          </Restricted>
          <Restricted to="ROLE_FINANCE_PAYMENT_INQUIRE_ABOUT_DEBT">
            <MenuItem to="/finance/operations/debt-inquiry" title="Ödeme Hareketleri" />
          </Restricted>
          <Restricted to="ROLE_FINANCE_PAYMENT_END_OF_DAY_REPORT">
            <MenuItem to="/finance/operations/end-of-day-report" title="Gün Sonu Raporu" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_BULK_TRANSACTION_REGISTRATION">
        <MenuInnerWithSub title="Toplu" to="/bulk-transactions" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuItem to="/bulk-transactions/registration" title="Öğrenci İşlemleri" />
          <MenuItem to="/bulk-transactions/payments" title="Ödeme İşlemleri" />
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_DEVICES">
        <MenuInnerWithSub title="Cihazlar" to="/device-operations" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuItem to="/device-operations/my-devices" title="Cihazlarım" />
        </MenuInnerWithSub>
      </Restricted>

      <Restricted to="ROLE_REPORTS">
        <MenuInnerWithSub title="Raporlama" to="/reports" menuPlacement="bottom-start" menuTrigger={`{default:'click', lg: 'hover'}`}>
          <Restricted to="ROLE_REPORTS_USER_REPORT">
            <MenuItem to="/reports/user" title="Kullanıcı Raporu" />
          </Restricted>
          <Restricted to="ROLE_REPORTS_APPLICATION_REPORT">
            <MenuItem to="/reports/registration" title="Başvuru Raporu" />
          </Restricted>
          <Restricted to="ROLE_REPORTS_FINANCE_REPORT">
            <MenuItem to="/reports/payment_statements" title="Finans Raporu" />
          </Restricted>
        </MenuInnerWithSub>
      </Restricted>
      {/* <Restricted to="ROLE_SUPPORT_REQUESTS">
        <MenuItem title={'Destek'} to="/tickets" />
      </Restricted> */}
    </>
  );
}
