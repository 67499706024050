/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Restricted from 'src/app/permissions/restricted';
import { KTSVG } from '../../../helpers';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <Restricted to="ROLE_DASHBOARD">
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotone/Design/PenAndRuller.svg"
          // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          title="Anasayfa"
          fontIcon="bi-app-indicator"
        />
      </Restricted>
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Başvuru Yönetimi</span>
        </div>
      </div> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Başvurular</span>
        </div>
      </div> */}
      <Restricted to="ROLE_APPLICATIONS">
        <div className="registration-joyride">
          <AsideMenuItemWithSub to="/applications" title="Öğrenci İşlemleri" fontIcon="bi-archive" icon="/media/icons/duotone/Code/Compiling.svg">
            <AsideMenuItemWithSub to="/applications/registration" title="Kayıt İşlemleri" icon="/media/icons/duotone/General/User.svg" fontIcon="bi-person">
              <AsideMenuItem to="/applications/registration" title="Başvurular" hasBullet={true}></AsideMenuItem>
              <Restricted to="ROLE_APPLICATIONS_AGREEMENT">
                <AsideMenuItem to="/applications/agreement" title="Sözleşme" hasBullet={true} />
              </Restricted>
            </AsideMenuItemWithSub>
            <Restricted to="ROLE_APPLICATIONS_EXAM">
              <AsideMenuItemWithSub to="/applications/exam" title="Sınav İşlemleri" icon="/media/icons/duotone/General/Bookmark.svg" fontIcon="bi-person">
                <AsideMenuItem to="/applications/exam/list" title="Başvurular" hasBullet={true}></AsideMenuItem>
                <Restricted to="ROLE_APPLICATIONS_EXAM_SESSIONS">
                  <AsideMenuItem to="/applications/exam/sessions" title="Oturumlar" hasBullet={true} />
                </Restricted>
              </AsideMenuItemWithSub>
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Site Yönetimi</span>
        </div>
      </div> */}
      <Restricted to="ROLE_SITE_OPERATIONS">
        <div className="site-joyride">
          <AsideMenuItemWithSub to="/site-operations" title="Site İşlemleri" icon="/media/icons/duotone/General/Settings-1.svg" fontIcon="bi-person">
            <Restricted to="ROLE_SITE_OPERATIONS_ANNOUNCEMENTS">
              <AsideMenuItemWithSub to="/site-operations/announcement" title="Duyuru İşlemleri" icon="/media/icons/duotone/General/Bookmark.svg" fontIcon="bi-person">
                <AsideMenuItem to="/site-operations/announcement/list" title="Duyurular" hasBullet={true}></AsideMenuItem>
                <Restricted to="ROLE_SITE_OPERATIONS_ANNOUNCEMENTS_TYPE">
                  <AsideMenuItem to="/site-operations/announcement/types" title="Duyuru Tipleri" hasBullet={true} />
                </Restricted>
              </AsideMenuItemWithSub>
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_COMMUNICATION_OPERATIONS">
              <AsideMenuItemWithSub to="/site-operations/contact" title="İletişim İşlemleri" icon="/media/icons/duotone/Communication/Call.svg" fontIcon="bi-person">
                <Restricted to="ROLE_SITE_OPERATIONS_COMMUNICATION_OPERATIONS_SETTINGS">
                  <AsideMenuItem to="/site-operations/contact/info" title="İletişim Ayarları" hasBullet={true}></AsideMenuItem>
                </Restricted>
                <Restricted to="ROLE_SITE_OPERATIONS_COMMUNICATION_OPERATIONS_CONTACT_REQUESTS">
                  <AsideMenuItem to="/site-operations/contact/list" title="İletişim Talepleri" hasBullet={true} />
                </Restricted>
              </AsideMenuItemWithSub>
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_ABOUT">
              <AsideMenuItem to="/site-operations/aboutus" title="Hakkımızda" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_FOUNDERS">
              <AsideMenuItem to="/site-operations/founders" title="Kurucular" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_JOB_APPLICATIONS">
              <AsideMenuItem to="/site-operations/job" title="İş Başvuruları" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_MENUS">
              <AsideMenuItem to="/site-operations/menus" title="Menüler" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_PAGES">
              <AsideMenuItem to="/site-operations/pages" title="Sayfalar" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_POPUP">
              <AsideMenuItem to="/site-operations/popup" title="Pop-up" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_SEO_SETTINGS">
              <AsideMenuItem to="/site-operations/seo" title="Seo Ayarları" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_SLIDE">
              <AsideMenuItem to="/site-operations/slide" title="Slayt" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_SITE_OPERATIONS_SUBSCRIBERS">
              <AsideMenuItem to="/site-operations/subscribers" title="Aboneler" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>

      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Personel Yönetimi</span>
        </div>
      </div> */}
      <Restricted to="ROLE_EMPLOYEES">
        <div className="personal-joyride">
          <AsideMenuItemWithSub to="/employees" title="Personel İşlemleri" icon="/media/icons/duotone/General/Settings-1.svg" fontIcon="bi-person">
            <Restricted to="ROLE_EMPLOYEES">
              <AsideMenuItem to="/employees/lists" title="Personel Yönetimi" hasBullet={true} />
            </Restricted>

            <Restricted to="ROLE_MAJORS">
              <AsideMenuItem to="/employees/majors" title="Branş Yönetimi" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>

      {/* <AsideMenuItem
        to="/apps/chat"
        icon="/media/icons/duotone/Communication/Chat.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="Mesajlar"
        fontIcon="bi-app-indicator"
      /> */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Ders Yönetimi</span>
        </div>
      </div> */}
      <Restricted to="ROLE_LESSONS">
        <div className="lessons-joyride">
          <AsideMenuItemWithSub to="/lessons" title="Dersler" icon="/media/icons/duotone/Home/Book.svg" fontIcon="bi-layers">
            <Restricted to="ROLE_LESSONS_MANAGEMENT">
              <AsideMenuItem to="/lessons/management" title="Ders Yönetimi" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_LESSONS_CLASSES">
              <AsideMenuItem to="/lessons/classes" title="Sınıflar" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_LESSONS_BRANCH">
              <AsideMenuItem to="/lessons/branch" title="Şubeler" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_LESSONS_TIMETABLE">
              <AsideMenuItem to="/lessons/timetable" title="Ders Programı" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_LESSONS_EXAMINATION">
              <AsideMenuItem to="/lessons/examination" title="Yoklama" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      <Restricted to="ROLE_OPTICAL">
        <div className="optical-joyride">
          <AsideMenuItemWithSub to="/optical" title="Sınav İşlemleri" icon="/media/icons/duotone/Files/File.svg" fontIcon="bi-layers">
            <Restricted to="ROLE_OPTICAL_FORMS">
              <AsideMenuItem to="/optical/form/list" title="Form Yönetimi" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_OPTICAL_EXAMS">
              <AsideMenuItem to="/optical/exams" title="Sınavlar" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      <Restricted to="ROLE_HOMEWORK_PROCEDURES">
        <div className="homeworks-joyride">
          <AsideMenuItemWithSub to="/homework" title="Ödev İşlemleri" icon="/media/icons/duotone/Layout/Layout-4-blocks.svg" fontIcon="bi-layers">
            <Restricted to="ROLE_HOMEWORKS">
              <AsideMenuItem to="/homework/lists" title="Ödevler" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_HOMEWORKS_EVALUATION">
              <AsideMenuItem to="/homework/evaluation" title="Değerlendirme" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Finans Yönetimi</span>
        </div>
      </div> */}
      <Restricted to="ROLE_FINANCE">
        <div className="finance-joyride">
          <AsideMenuItemWithSub to="/finance" title="Finans" icon="/media/icons/duotone/Layout/Layout-grid.svg" fontIcon="bi-layers">
            <Restricted to="ROLE_FINANCE_INCOME_EXPENSES">
              <AsideMenuItemWithSub to="/finance/income" title="Gelir/Gider İşlemleri" icon="/media/icons/duotone/Shopping/Money.svg" fontIcon="bi-person">
                <AsideMenuItem to="/finance/income/list" title="Gelir/Gider Bilgileri" hasBullet={true}></AsideMenuItem>
                <Restricted to="ROLE_FINANCE_INCOME_EXPENSES_TYPE">
                  <AsideMenuItem to="/finance/income/types" title="Gelir/Gider Tipleri" hasBullet={true} />
                </Restricted>
              </AsideMenuItemWithSub>
            </Restricted>
            <Restricted to="ROLE_CURRENT_ACCOUNTS">
              <AsideMenuItem to="/finance/current-accounts" title="Cari Hesap Yönetimi" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_FINANCE_CASH">
              <AsideMenuItem to="/finance/cashes" title="Kasalar" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_FINANCE_BANK">
              <AsideMenuItem to="/finance/banks" title="Bankalar" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_BALANCE_TRANSFER">
              <AsideMenuItem to="/finance/transfer" title="Virman İşlemleri" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_FINANCE_PAYMENT">
              <AsideMenuItem to="/finance/operations/lists" title="Ödemeler" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_FINANCE_PAYMENT_GIB">
              <AsideMenuItem to="/finance/operations/e-arsiv" title="G.İ.B e-arşiv" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_FINANCE_PAYMENT_INQUIRE_ABOUT_DEBT">
              <AsideMenuItem to="/finance/operations/debt-inquiry" title="Ödeme Hareketleri" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_FINANCE_PAYMENT_END_OF_DAY_REPORT">
              <AsideMenuItem to="/finance/operations/end-of-day-report" title="Gün Sonu Raporu" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Toplu İşlemler</span>
        </div>
      </div> */}
      <Restricted to="ROLE_BULK_TRANSACTION_REGISTRATION">
        <div className="bulk-joyride">
          <AsideMenuItemWithSub to="/bulk-transactions" title="Toplu İşlemler" icon="/media/icons/duotone/Files/Folder-star.svg" fontIcon="bi-layers">
            <AsideMenuItem to="/bulk-transactions/registration" title="Öğrenci İşlemleri" hasBullet={true} />
            <AsideMenuItem to="/bulk-transactions/payments" title="Ödeme İşlemleri" hasBullet={true} />
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Cihaz İşlemleri</span>
        </div>
      </div> */}
      <Restricted to="ROLE_DEVICES">
        <div className="device-joyride">
          <AsideMenuItemWithSub to="/device-operations" title="Cihaz İşlemleri" icon="/media/icons/duotone/Layout/Layout-horizontal.svg" fontIcon="bi-layers">
            <AsideMenuItem to="/device-operations/my-devices" title="Cihazlarım" hasBullet={true} />
          </AsideMenuItemWithSub>
        </div>
      </Restricted>

      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Rapor Yönetimi</span>
        </div>
      </div> */}
      <Restricted to="ROLE_REPORTS">
        <div className="report-joyride">
          <AsideMenuItemWithSub to="/reports" title="Raporlama" icon="/media/icons/duotone/Layout/Layout-horizontal.svg" fontIcon="bi-layers">
            <Restricted to="ROLE_REPORTS_USER_REPORT">
              <AsideMenuItem to="/reports/user" title="Kullanıcı Raporu" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_REPORTS_APPLICATION_REPORT">
              <AsideMenuItem to="/reports/registration" title="Başvuru Raporu" hasBullet={true} />
            </Restricted>
            <Restricted to="ROLE_REPORTS_FINANCE_REPORT">
              <AsideMenuItem to="/reports/payment_statements" title="Finans Raporu" hasBullet={true} />
            </Restricted>
          </AsideMenuItemWithSub>
        </div>
      </Restricted>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Destek</span>
        </div>
      </div>
      {/* <Restricted to="ROLE_SUPPORT_REQUESTS">
        <div className="ticket-joyride">
          <AsideMenuItem to="/tickets" icon="/media/icons/duotone/Communication/Mail-opened.svg" title="Destek Talepleri" fontIcon="bi-app-indicator" />
        </div>
      </Restricted> */}

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>

      <div className="release-joyride">
        <div className="menu-item">
          <Link className="menu-link" to={'/release-notes'}>
            <span className="menu-icon">
              <KTSVG path="/media/icons/duotone/Files/File.svg" className="svg-icon-2" />
            </span>
            <span className="menu-title">Sürüm Notları {process.env.REACT_APP_VERSION}</span>
          </Link>
        </div>
      </div>
    </>
  );
}
