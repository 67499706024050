/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import { Formik, Form, FormikValues, Field, ErrorMessage, FormikProps } from 'formik';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/services/redux/app/store';
import { companyList } from 'src/services/redux/slices/auth/auth-slice';
import isCreditCard from 'validator/lib/isCreditCard';
import * as Yup from 'yup';
import { StepperComponent } from '../../../assets/ts/components';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useIyzipayInstallment, useProductsActive } from './service';
import { useIyzipayCreate } from './service';

type Props = {
  show: boolean;
  handleClose: () => void;
};

export const Main: FC<Props> = ({ handleClose, show }) => {
  interface ICreateAccount {
    contactName: string;
    city: string;
    address: string;
    zipCode: string;
    cardHolderName: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvc: string;
    month_to_be_renewed: number;
    installment: string;
    installmentTotalPrice: number | string;
    registerCard: boolean;
    acceptTerms: boolean;
  }
  const inits: ICreateAccount = {
    contactName: '',
    city: '',
    address: '',
    zipCode: '',
    cardHolderName: '',
    cardNumber: '',
    expireMonth: '',
    expireYear: '',
    cvc: '',
    month_to_be_renewed: 1,
    installment: '',
    installmentTotalPrice: '',
    registerCard: false,
    acceptTerms: false,
  };
  const createAppSchema = [
    Yup.object({
      contactName: Yup.string().required().label('contactName'),
      city: Yup.string().required(),
      address: Yup.string().required(),
      zipCode: Yup.string(),
    }),

    Yup.object({
      cardHolderName: Yup.string().required().label('Name'),
      cardNumber: Yup.string().test('isCreditCart', 'Lütfen geçerli bir kredi kartı belirtin.', (val: any) => val && isCreditCard(val)),
      expireMonth: Yup.string().required().label('Expiration Month'),
      expireYear: Yup.string().required().label('Expiration Year'),
      cvc: Yup.string().required().label('CVV'),
      month_to_be_renewed: Yup.string().required().label('month_to_be_renewed Month'),
      installment: Yup.string().required('Lütfen taksit seçeneğini belirtin.').label('installment'),
      installmentTotalPrice: Yup.string().required().label('installmentTotalPrice'),
      acceptTerms: Yup.bool().required('Şartları ve koşulları kabul etmelisiniz'),
    }),
  ];
  const formikRef = useRef<FormikProps<any>>(null);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [stepperState, setStepperState] = useState<StepperComponent | null>();
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
  const [initValues] = useState<ICreateAccount>(inits);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [installmentParams, setInstallmentParams] = useState<any>({ price: user?.company?.subscription_price });
  const [totalPrice, setTotalPrice] = useState<any>();

  const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
  const {
    refetch: installmentRefetch,
    data: installmentList,
    isFetching: installmentIsFetching,
    isLoading: installmentIsLoading,
  } = useIyzipayInstallment({ ...installmentParams }, onErrorFetch);
  const { refetch: productActiveRefetch, data: productActiveList, isFetching: productActiveIsFetching, isLoading: productActiveIsLoading } = useProductsActive(onErrorFetch);
  const { data: createPaymentData, isLoading: createPaymentIsLoading, mutate: mutateCreatePayment, reset, isError: createPaymentIsError } = useIyzipayCreate(onErrorFetch);

  const [installmentData, setInstallmentData] = useState<any>();
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    setStepperState(stepper.current);
  };

  const prevStep = () => {
    if (!stepperState) {
      return;
    }

    stepperState.goPrev();

    setCurrentSchema(createAppSchema[stepperState.currentStepIndex - 1]);
  };

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepperState) {
      return;
    }
    if (stepperState.currentStepIndex === 2) {
      mutateCreatePayment({ ...values, price: installmentParams?.price });
    } else if (stepperState.currentStepIndex === 4) {
      dissmisLocation();
    } else {
      setCurrentSchema(createAppSchema[stepperState.currentStepIndex]);
      if (stepperState.currentStepIndex !== stepperState.totatStepsNumber) {
        stepperState.goNext();
      } else {
        stepperState.goto(1);
        actions.resetForm();
      }
    }
  };
  const dissmisLocation = () => {
    formikRef?.current?.resetForm();
    if (paymentStatus) dispatch(companyList());
    handleClose();
  };

  useEffect(() => {
    if (productActiveList) {
      const nowDate = moment();
      const expiryDate = moment(user?.company?.subscription_expiry_date);
      const remainingDay = expiryDate.diff(nowDate, 'day');
      if (remainingDay < 5) {
        const devicePrice = user?.products?.[0]?.payment_required ? user?.products?.[0]?.total_price_due : 0;
        setTotalPrice(
          user?.company?.subscription_price +
            devicePrice +
            productActiveList.reduce((accumulator: any, ie: any) => {
              return accumulator + (ie?.price || 0);
            }, 0),
        );
        setInstallmentParams((prevState: any) => ({
          ...prevState,
          price:
            user?.company?.subscription_price +
            devicePrice +
            productActiveList.reduce((accumulator: any, ie: any) => {
              return accumulator + (ie?.price || 0);
            }, 0),
        }));
      } else if (user?.products?.[0]?.payment_required) {
        setTotalPrice(user?.products?.[0]?.total_price_due);
        setInstallmentParams((prevState: any) => ({
          ...prevState,
          price: user?.products?.[0]?.total_price_due,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productActiveList]);
  useEffect(() => {
    if (!createPaymentIsError && !createPaymentIsLoading) {
      if (!stepperState) {
        return;
      }
      stepperState.goNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPaymentIsError, createPaymentIsLoading]);

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef, show]);

  useEffect(() => {
    setInstallmentData(installmentList);
  }, [installmentList]);

  useEffect(() => {
    if (installmentParams?.price && installmentParams?.binNumber) {
      installmentRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentParams]);

  useEffect(() => {
    const handler = (ev: MessageEvent<{ success: boolean }>) => {
      if (ev?.data?.success) {
        setPaymentStatus(true);
        formikRef?.current?.handleSubmit();
      } else {
        setPaymentStatus(false);
        stepperState?.goPrev();
      }
    };

    window.addEventListener('message', handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener('message', handler);
  }, []);

  return (
    <Modal show={show} size="xl" onHide={dissmisLocation} centered className=" modal-dialog-centered">
      <div className="modal-content ">
        <div className="modal-header">
          <h2 className="modal-title" id="staticBackdropLabel">
            Ödeme
          </h2>
          <button type="button" className="btn-close" onClick={dissmisLocation} aria-label="Close"></button>
        </div>

        <div className="modal-body py-lg-10 px-lg-10">
          <div ref={stepperRef} className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper">
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              <div className="stepper-nav ps-lg-10">
                <div className="stepper-item current" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                    <span className="stepper-number">1</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Fatura Bilgileri</h3>

                    <div className="stepper-desc">Fatura bilgilerinizi belirtin</div>
                  </div>
                </div>
                <div className="stepper-item " data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                    <span className="stepper-number">2</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Ödeme</h3>

                    <div className="stepper-desc">Ödeme ayrıntılarını belirtin</div>
                  </div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                    <span className="stepper-number">3</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">3D Secure</h3>

                    <div className="stepper-desc">Güvenli ödeme yap</div>
                  </div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                    <span className="stepper-number">4</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Tamamlandı</h3>

                    <div className="stepper-desc">Onayla ve tamamla</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row-fluid py-lg-5 px-lg-15">
              <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep} innerRef={formikRef}>
                {({ errors, handleChange, handleBlur, touched, values, setFieldValue, getFieldProps }) => (
                  <Form className="form" noValidate id="kt_modal_create_app_form">
                    <div className="current" data-kt-stepper-element="content">
                      <div className="w-100">
                        <div className="pb-10 pb-lg-15">
                          <h2 className="fw-bolder text-dark">Fatura Adresi</h2>
                          <div className="text-gray-400 fw-bold fs-6">
                            Daha fazla bilgiye ihtiyacınız varsa, lütfen{' '}
                            <a href="#" className="text-primary fw-bolder">
                              Yardım Sayfasına
                            </a>{' '}
                            bakın.
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-7 fv-row">
                          <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                            <span className="required">Adı Soyadı</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Kart sahibinin adını belirtin"></i>
                          </label>
                          <input
                            type="text"
                            className={`form-control form-control-solid ${errors.contactName && touched.contactName && 'is-invalid border-danger'}`}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="contactName"
                          />
                        </div>
                        <div className="row mb-10">
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-bold form-label mb-2">Şehir</label>
                            <div className="row fv-row">
                              <div className="col-12">
                                <input
                                  type="text"
                                  name="city"
                                  className={`form-control form-control-solid ${errors.city && touched.city && 'is-invalid border-danger'}`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 fv-row">
                            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                              <span className="">Posta Kodu</span>
                              <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Enter a card CVV code"></i>
                            </label>
                            <input
                              type="number"
                              name="zipCode"
                              className={`form-control form-control-solid ${errors.zipCode && touched.zipCode && 'is-invalid border-danger'}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-7 fv-row">
                          <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                            <span className="required">Adres</span>
                          </label>
                          <textarea
                            className={`form-control form-control-solid ${errors.address && touched.address && 'is-invalid border-danger'}`}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="address"
                          />
                        </div>
                      </div>
                    </div>
                    <div data-kt-stepper-element="content">
                      <div className="w-100">
                        <div className="pb-10 pb-lg-15">
                          <h2 className="fw-bolder text-dark">Ödeme İşlemi</h2>
                          <div className="text-gray-400 fw-bold fs-6">
                            Daha fazla bilgiye ihtiyacınız varsa, lütfen{' '}
                            <a href="#" className="text-primary fw-bolder">
                              Yardım Sayfasına
                            </a>{' '}
                            bakın.
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-7 fv-row">
                          <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                            <span className="required">Kart Üzerindeki İsim</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Kart sahibinin adını belirtin"></i>
                          </label>
                          <input
                            type="text"
                            className={`form-control form-control-solid ${errors.cardHolderName && touched.cardHolderName && 'is-invalid border-danger'}`}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="cardHolderName"
                          />
                        </div>
                        <div className="d-flex flex-column mb-7 fv-row">
                          <label className="required fs-6 fw-bold form-label mb-2">Kart Numarası</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className={`form-control form-control-solid ${errors.cardNumber && touched.cardNumber && 'is-invalid border-danger'}`}
                              placeholder=""
                              onChange={(e) => {
                                setFieldValue('cardNumber', e.target.value);
                                setInstallmentData(null);
                                setFieldValue('installmentTotalPrice', '');
                                if (e.target.value.length >= 6) {
                                  setInstallmentParams((prevState: any) => ({
                                    ...prevState,
                                    binNumber: e.target.value?.slice(0, 6),
                                  }));
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.cardNumber}
                              name="cardNumber"
                            />
                            <div className="position-absolute translate-middle-y top-50 end-0 me-10">
                              <img src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')} alt="" className="h-25px" />
                              <img src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')} alt="" className="h-25px" />
                              <img src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')} alt="" className="h-25px" />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-md-8 fv-row">
                            <label className="required fs-6 fw-bold form-label mb-2">Son Kullanma Tarihi</label>
                            <div className="row fv-row">
                              <div className="col-6">
                                <select
                                  name="expireMonth"
                                  className={`form-select form-select-solid ${errors.expireMonth && touched.expireMonth && 'is-invalid border-danger'}`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.expireMonth}
                                >
                                  <option></option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </select>
                              </div>

                              <div className="col-6">
                                <select
                                  name="expireYear"
                                  className={`form-select form-select-solid ${errors.expireYear && touched.expireYear && 'is-invalid border-danger'}`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.expireYear}
                                >
                                  <option></option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                  <option value="2026">2026</option>
                                  <option value="2027">2027</option>
                                  <option value="2028">2028</option>
                                  <option value="2029">2029</option>
                                  <option value="2030">2030</option>
                                  <option value="2031">2031</option>
                                  <option value="2031">2032</option>
                                  <option value="2031">2033</option>
                                  <option value="2031">2034</option>
                                  <option value="2031">2035</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 fv-row">
                            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                              <span className="required">CVV</span>
                              <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Enter a card CVV code"></i>
                            </label>

                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="CVV"
                                name="cvc"
                                className={`form-control form-control-solid ${errors.cvc && touched.cvc && 'border-danger'}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                <KTSVG path="/media/icons/duotone/Shopping/Credit-card.svg" className="svg-icon-2hx" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-md-12 fv-row">
                            <label className="required fs-6 fw-bold form-label mb-2">Yenileme Süresi</label>

                            <select
                              name="month_to_be_renewed"
                              className={`form-select form-select-solid ${errors.month_to_be_renewed && touched.month_to_be_renewed && 'is-invalid border-danger'}`}
                              onChange={(e: any) => {
                                setFieldValue('month_to_be_renewed', e.target.value);
                                setInstallmentData(null);
                                setFieldValue('installmentTotalPrice', '');
                                setInstallmentParams((prevState: any) => ({
                                  ...prevState,
                                  price: Number(e.target.value) * totalPrice,
                                }));
                              }}
                              onBlur={handleBlur}
                              value={values.month_to_be_renewed}
                            >
                              <option value={1}>1 Ay</option>
                              <option value={2}>2 Ay</option>
                              <option value={3}>3 Ay</option>
                              <option value={4}>4 Ay</option>
                              <option value={5}>5 Ay</option>
                              <option value={6}>6 Ay</option>
                              <option value={7}>7 Ay</option>
                              <option value={8}>8 Ay</option>
                              <option value={9}>9 Ay</option>
                              <option value={10}>10 Ay</option>
                              <option value={11}>11 Ay</option>
                              <option value={12}>12 Ay</option>
                            </select>
                          </div>
                        </div>
                        {values.cardNumber.length > 5 && (installmentIsFetching || installmentIsLoading) && (
                          <div className="text-center mb-10">
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </div>
                        )}
                        {installmentData && (
                          <div className="row mb-10">
                            <div className="table-responsive">
                              {/* begin::Table */}
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                {/* begin::Table head */}
                                <thead>
                                  <tr className="fw-bolder text-muted">
                                    <th className="min-w-25px"></th>
                                    <th className="min-w-100px">Taksit seçnekleri</th>
                                    <th className="min-w-100px">Aylık</th>
                                    <th className="min-w-100px text-end">Toplam</th>
                                  </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                  {installmentData?.installmentDetails[0]?.installmentPrices?.map((list: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                          <input
                                            className="form-check-input widget-9-check"
                                            name="installment"
                                            id={`installment-${index}`}
                                            type="radio"
                                            value={list?.installmentNumber}
                                            onChange={(e) => {
                                              setFieldValue('installment', e.target.value);
                                              setFieldValue('installmentTotalPrice', installmentData?.installmentDetails[0]?.installmentPrices[index].totalPrice);
                                            }}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                      </td>
                                      <td>{index === 0 ? 'Peşin' : list?.installmentNumber + ' Taksit'}</td>
                                      <td>{list?.installmentPrice?.toLocaleString()} ₺</td>
                                      <td className="text-end">{list?.totalPrice?.toLocaleString()} ₺</td>
                                    </tr>
                                  ))}
                                </tbody>
                                {/* end::Table body */}
                              </table>
                              {/* end::Table */}
                            </div>
                            {errors.installment && touched.installment && <span className="text-danger">{errors.installment}</span>}
                          </div>
                        )}
                        <div className="form-check form-check-custom form-check-solid mb-10">
                          <input className="form-check-input" type="checkbox" id="kt_login_toc_agree" {...getFieldProps('acceptTerms')} />
                          <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
                            <a href="https://test-eduthon.apithon.com.tr/privacy-and-security" target={'_blank'} rel="noreferrer" className="ms-1 link-primary">
                              Gizlilik ve Güvenlik Sözleşmesi,
                            </a>
                            <a href="https://test-eduthon.apithon.com.tr/cancel-refund" target={'_blank'} rel="noreferrer" className="ms-1 link-primary">
                              İptal ve İade Koşulları
                            </a>
                            'ını okudum ve kabul ediyorum.
                          </label>
                          {touched.acceptTerms && errors.acceptTerms && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert" className="text-danger">
                                  {errors.acceptTerms}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div className="form-check form-check-custom form-check-solid mb-10">
                          <input className="form-check-input" type="checkbox" id="kt_login_toc_agree" {...getFieldProps('acceptTerms')} />
                          <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
                            <a href="https://test-eduthon.apithon.com.tr/privacy-and-security" target={'_blank'} rel="noreferrer" className="ms-1 link-primary">
                              Ödeme hizmetleri sözleşmesi
                            </a>
                            'ni okudum ve kabul ediyorum.
                          </label>
                          {touched.acceptTerms && errors.acceptTerms && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert" className="text-danger">
                                  {errors.acceptTerms}
                                </span>
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div className="d-flex flex-stack mb-10">
                          <div className="me-5">
                            <label className="fs-6 fw-bold form-label">Bir sonraki ödeme için kart bilgileri kaydedilsin mi?</label>
                            <div className="fs-7 fw-bold text-gray-400">
                              Kart bilgileriniz iyzico tarafından güvenle saklanmaktadır. Daha fazla bilgiye ihtiyacınız varsa, lütfen bizimle iletişime geçin.
                            </div>
                          </div>

                          <label className="form-check form-switch form-check-custom form-check-solid">
                            <Field className="form-check-input" type="checkbox" name="registerCard" />
                          </label>
                        </div>

                        <div className="d-flex justify-content-center align-items-center">
                          <img src={toAbsoluteUrl('/media/iyzico/iyzico_ile_ode_colored.png')} alt="" className="h-35px me-3" />
                          <img src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')} alt="" className="h-35px me-3" />
                          <img src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')} alt="" className="h-35px" />
                        </div>
                        {values?.installmentTotalPrice !== '' && <div className="fw-bolder fs-6 text-end">Toplam : {values?.installmentTotalPrice} ₺</div>}
                      </div>
                    </div>

                    <div data-kt-stepper-element="content">
                      <div className="w-100 text-center">
                        {createPaymentData?.data && (
                          <iframe
                            srcDoc={createPaymentData?.data?.threeDSHtmlContent}
                            style={{
                              maxWidth: 900,
                              width: '100%',
                            }}
                            width={'100%'}
                            height={400}
                            title="Ödeme"
                          />
                        )}
                      </div>
                    </div>

                    <div data-kt-stepper-element="content">
                      <div className="w-100 text-center">
                        <h1 className="fw-bolder text-dark mb-3">İşlem Tamamlandı!</h1>

                        <div className="text-muted fw-bold fs-3">Yenileme işleminiz başarıyla tamamlandı.</div>

                        <div className="text-center px-4 py-15">
                          <img src={toAbsoluteUrl('/media/illustrations/todo.png')} alt="" className="mw-100 mh-150px" />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-stack pt-10">
                      <div className="me-2">
                        <button onClick={prevStep} type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                          <KTSVG path="/media/icons/duotone/Navigation/Left-2.svg" className="svg-icon-4 me-1" />
                          Geri
                        </button>
                      </div>
                      <div>
                        {stepperState?.currentStepIndex !== 3 && (
                          <button type="submit" className="btn btn-lg btn-primary me-3">
                            <span className="indicator-label">
                              {stepperState?.currentStepIndex === 1 && 'İlerle'}
                              {stepperState?.currentStepIndex === 4 && 'Bitti'}
                              {stepperState?.currentStepIndex === 2 && `Ödeme Yap`}
                              {!createPaymentIsLoading && <KTSVG path="/media/icons/duotone/Navigation/Right-2.svg" className="svg-icon-3 ms-2 me-0" />}

                              {createPaymentIsLoading && <span className="spinner-border spinner-border-sm align-middle mx-2 text-white"></span>}
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
