/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Main } from 'src/_metronic/partials';
import { useAppSelector } from 'src/services/redux/app/store';
import { KTSVG } from '../../../helpers';
import { useLayout } from '../../core';
import { DefaultTitle } from '../header/page-title/DefaultTitle';

const ToolbarPayment: FC = () => {
  const { classes } = useLayout();
  const [paymentModal, setPaymentModal] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const nowDate = moment();
  const expiryDate = moment(user?.company?.subscription_expiry_date);
  const remainingDay = expiryDate.diff(nowDate, 'day');
  if (remainingDay < 5)
    return (
      <div className="toolbar" id="kt_toolbar">
        {/* begin::Container */}
        <div id="kt_toolbar_container" className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}>
          <span className="d-flex align-items-center ">
            <KTSVG path="/media/icons/duotone/Home/Circle-info-solid.svg" className="svg-icon-primary text-primary me-5  " svgClassName="w-20px h-20px" />
            {remainingDay > 0 ? (
              <div>
                Aboneliğinizin bitmesine <b>{remainingDay}</b> gün kaldı.
                <br />
                <b>{moment(expiryDate).format('DD.MM.YYYY')} </b>tarihine kadar aboneliğiniz devam ediyor. Yenilemek için lütfen ödeme yapın.
              </div>
            ) : (
              'Aboneliğiniz Bitti'
            )}
            {'\n'}
          </span>

          {/* begin::Actions */}
          <div className="d-flex align-items-center py-1">
            {/* begin::Wrapper */}

            {/* end::Wrapper */}

            {/* begin::Button */}

            <button className="btn btn-sm btn-light-danger" onClick={() => setPaymentModal(true)}>
              Ödeme Yap
            </button>
            {/* end::Button */}
          </div>
          {/* end::Actions */}
        </div>
        <Main show={paymentModal} handleClose={() => setPaymentModal(false)} />

        {/* end::Container */}
      </div>
    );
  if (user?.products?.[0]?.payment_required)
    return (
      <div className="toolbar" id="kt_toolbar">
        {/* begin::Container */}
        <div id="kt_toolbar_container" className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}>
          <span className="d-flex align-items-center ">
            <KTSVG path="/media/icons/duotone/Home/Circle-info-solid.svg" className="svg-icon-primary text-primary me-5  " svgClassName="w-20px h-20px" />
            {remainingDay > 0 ? (
              <div>
                Cihaz aboneliğinizin son ödeme tarihinden <b>{user?.products?.[0]?.delay_months}</b> ay geçmiş bulunmaktadır.
              </div>
            ) : (
              'Aboneliğiniz Bitti'
            )}
            {'\n'}
          </span>

          {/* begin::Actions */}
          <div className="d-flex align-items-center py-1">
            {/* begin::Wrapper */}

            {/* end::Wrapper */}

            {/* begin::Button */}

            <button className="btn btn-sm btn-light-danger" onClick={() => setPaymentModal(true)}>
              Ödeme Yap
            </button>
            {/* end::Button */}
          </div>
          {/* end::Actions */}
        </div>
        <Main show={paymentModal} handleClose={() => setPaymentModal(false)} />

        {/* end::Container */}
      </div>
    );
  else return null;
};

export { ToolbarPayment };
